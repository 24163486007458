<template>
  <div class="device_finger_wrap">
    <!-- <json-viewer :value="responseData" copyable theme="my-awesome-json-theme"></json-viewer> -->
    <div v-if="hasResult" class="result_stage_r">
      <div class="result_show" v-for="(val, key, ind) in responseData" :key="ind" :title="key" :name="ind">
        <h2 class="result_title">{{key | chooseFilterText}}</h2>
        <el-divider class="result_title_divider"></el-divider>
        <el-row type="flex" class="row_box" v-for="(v, k, index) in val" :key="index">
          <el-col :xs="10" :md="5">
            <div class="grid_key">
              {{k | chooseFilterText}}
            </div>
          </el-col>
          <el-col :xs="14" :md="19">
            <div class="grid_val">
              {{v | dealValueFilter}}
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-empty v-else description="Temporarily no data"></el-empty>
  </div>
</template>

<script>
/* eslint-disable */
import JsonViewer from 'vue-json-viewer'
import './tool/min.js'
import { upLoadToken, deviceInfo } from '@/api/modules/devicefinger'

export default {
  data() {
    return {
      responseData: {},
      sucData: {},
      activeNames: [0],
      dfpId: '',
      beingLoading: false,
      hasResult: false,
      timerDealSm: null
    };
  },
  filters: {
    dealValueFilter(val) {
      let res = val
      if (val === null) {
        res = '-'
      }
      return res
    },
    chooseFilterText(val) {
      let valBlankSpace = ''
      // const _this = that._self
      if (Object.prototype.toString.call(val) === '[object String]' && val.length > 0) {
        let strCase = ''
        if (val === val.toUpperCase()) {
          strCase = val
        } else {
          val = val.replace(val[0],val[0].toLowerCase())
          const str = val.replace(/([A-Z])/g," $1")
          strCase = str.toLowerCase().replace(/\b([\w|']+)\b/g, (word) => {
            return word.replace(word.charAt(0), word.charAt(0).toUpperCase())
          })
        }
        const proceStrCase = strCase.replace('-', ' ')
        if ((proceStrCase.substr(0,2) === 'Id' || proceStrCase.substr(0,2) === 'id') && (proceStrCase.substr(2,1) === ' ')) {
          valBlankSpace = proceStrCase.substr(0,2).toUpperCase() + proceStrCase.substr(2)
        } else if (proceStrCase === 'Ip' || proceStrCase === 'ip') {
          console.log(proceStrCase)
        } else {
          valBlankSpace = proceStrCase.substr(0,1).toUpperCase() + proceStrCase.substr(1)
        }
      }
      if (valBlankSpace.length === 0) {
        valBlankSpace = val
      }
      return valBlankSpace
    }
  },
  created() {
    this.beingLoading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
  },
  components:{
    JsonViewer
  },
  mounted() {
    this.getMoreDealSmDeviceId()
    // const that = this
    // this.dealSmDeviceId(function(deviceId){ 
    //   window.console && console.log('回调执行成功，设备标识为：'+deviceId)
    //   that._getUpLoadToken(deviceId)
    // })
  },
  methods: {
    getMoreDealSmDeviceId() {
      const that = this
      this.dealSmDeviceId(function(deviceId){ 
        window.console && console.log('回调执行成功，设备标识为：'+deviceId)
        if (deviceId && deviceId.length > 200) {
          clearTimeout(that.timerDealSm)
          that.timerDealSm = setTimeout(() => {
            that.getMoreDealSmDeviceId()
          }, 2000)
        } else if (deviceId) {
          clearTimeout(that.timerDealSm)
          that._getUpLoadToken(deviceId)
        }
      })
    },
    dealSmDeviceId(cb) {
      var smTimeoutTime = 1000; 
      var smDeviceId = "";
      var smDeviceIdReady = false;
      var smTimer = setTimeout(function(){ 
        smDeviceId = SMSdk.getDeviceId ? SMSdk.getDeviceId() : smDeviceId; 
        if(!smDeviceIdReady) { 
          smDeviceIdReady = true; //执行业务逻辑 
          cb && cb(smDeviceId); 
          } 
        }, smTimeoutTime);
      SMSdk.ready(function(){ 
        smDeviceId = SMSdk.getDeviceId ? SMSdk.getDeviceId() : smDeviceId; 
        clearTimeout(smTimer); 
        if(!smDeviceIdReady) { 
          smDeviceIdReady = true; //执行业务逻辑 
          cb && cb(smDeviceId); 
          } 
        });
    },
    // bindEvent(element, eventType, func) {
    //   if (element.addEventListener) { 
    //     element.addEventListener(eventType, func, false); 
    //   } else if (element.attachEvent) { 
    //     eventType = 'on' + eventType; 
    //     element.attachEvent(eventType, func); 
    //   } else { 
    //     eventType = 'on' + eventType; 
    //     element[eventType] = func; 
    //   }
    // },
    dealStructure(agm) {
      let dataWarehouse
      try {
        dataWarehouse = JSON.parse(agm)
        return dataWarehouse;
      } catch (error) {
        return agm;
      } 
    },
    getRowClassName({row}) {
      if (!(row.hasObj) && !(row.hasArr)) {
        return 'row-expand-cover'
      }
    },
    async _getUpLoadToken(res) {
      const resStr = res || ''
      this.hasResult = false
      try{
        const { data } = await upLoadToken({token: resStr, source: 'SHUMEI_DEVICE_FINGER'})
        if (data.code !== 'SUCCESS') {
          this.$message({message: data.message, type: 'error', customClass: 'message_box'})
        } else {
          const { data: datainfo } = await deviceInfo({dfpId: data.data.dfpId})
          if (datainfo.code !== 'SUCCESS') {
            this.$message({message: data.message, type: 'error', customClass: 'message_box'})
          } else {
            this.hasResult = true
            this.responseData = datainfo.data
          }
        }
      } catch (e) {
        this.$message({message: e.message, type: 'error', customClass: 'message_box'})
      } finally {
        this.beingLoading.close()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .device_finger_wrap{
    padding: 30px 20px;
    .result_show{
      margin-bottom: 50px;
      .result_title{
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        background-image:linear-gradient(-45deg, #ffffff 0%, #ffffff 85%, rgba(18,76,112,0.2) 100%)
      }
      .result_title_divider{
        margin: 14px 0;
      }
      .row_box{
        margin-bottom: 20px;
        .grid_key{
          font-size: 14px;
          color: #666666;
          line-height: 18px;
        }
        .grid_val{
          word-wrap: break-word;
          font-weight: 14px;
          color: #999999;
        }
      }
    }
  }
</style>
