/* eslint-disable */
(function() {
  window._smReadyFuncs = []; 
  window.SMSdk = { 
    ready: function(fn) { 
      fn && _smReadyFuncs.push(fn); 
    }
  }; 
  // 1. 通用配置项 
  // protocol: 'https' // 如果使用 https，则设置，如不使用，则不设置这个字段 
  window._smConf = { 
    organization: '4BoLNzW8XseK3YDuu0KU', //必填，组织标识，邮件中 organization 项 
    appId : 'default', //必填，应用标识，默认传值 default，其他应用标识提前联系数美协助定义 
    publicKey : 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCJWQmX5oUy1qSfm560EkmEqGF1HWwREIqHVzceEc9FCGjXoKFCAYVQRdHSLnAWjjlG5ZJhPsd8+K6k/3kWoA/YK0qFNs2/1YnNbpTQxVkhXGZKHPQlZMBAkaOau3NpnRgs03HeksTOpOpltNU8v8Ev0LwLoKcGvWmyHDhLOSIS/wIDAQAB' , //必填，私钥标识，邮件中 publicKey 项 
    staticHost: 'static.portal101.cn', 
    // apiHost: 'fp-sa-it.fengkongcloud.com', //必填，转发服务器域名
    apiHost: 'fp-sa-it-acc.fengkongcloud.com', //必填，转发服务器域名
    // apiHost: 'fp-it-acc.fengkongcloud.com', //必填，转发服务器域名

  };
  var url = (function () {
    var originHost = "static2.fengkongcloud.com"; 
    var isHttps = 'https:' === document.location.protocol; 
    var protocol = isHttps ? 'https://' : 'http://'; 
    var fpJsPath = '/dist/web/v3.0.0/fp.min.js'; 
    var jsTimer = '?t=' + (new Date().getTime() / (6 * 3600 * 1000)).toFixed(0) 
    var url = protocol + _smConf.staticHost + fpJsPath + jsTimer; 
    var ua = navigator.userAgent.toLowerCase(); 
    var isWinXP = /windows\s(?:nt\s5.1)|(?:xp)/.test(ua); 
    var isLowIE = /msie\s[678]\.0/.test(ua); 
    if(isHttps && isWinXP && isLowIE) { 
      url = protocol + originHost + fpJsPath + jsTimer; 
    }
    return url; 
  })();
  var sm = document.createElement("script");
  var s = document.getElementsByTagName("script")[0]; 
  sm.src = url; 
  s.parentNode.insertBefore(sm, s);
})()
